<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="storageModal"
    :title="$t('Add') + ' ' +  $t(type)"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="items-end grid sm:grid-cols-2 grid-cols-1 gap-x-4">
      <div>
        <vs-input v-validate="'required'" :label-placeholder="$t(type) + ' ' + $t('ID')" name="id" v-model="box.id" :class="box.id ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-xs" v-show="errors.has('id')">{{ errors.first('id') }}</span>
        <span class="text-danger text-xs" v-if="!errors.has('id')">{{ box.id && box.id[0] !== type[0].toUpperCase() ? $t('First letter must be') + ' ' + type[0].toUpperCase() + ' ' + 'capital' : box.id && (box.id.length === 1 || /\D/.test(box.id.slice(1))) ? $t('This field must have numeric data after first character') : '' }}</span>
      </div>
      <div v-if="type === 'tote' || type === 'box'" class="sm:mt-0 mt-6">
        <v-select :class="box.storage_location ? 'input-customer-info-filled ' : ''" class="w-full input-customer-info product-dropdown" 
          autocomplete="nofill"  label="ops_id" :name="`shelf`" v-model="box.storage_location" 
          :placeholder="$t('Shelf ID') + '*'" :options="shelfId" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has(`shelf`)">{{ errors.first(`shelf`)}}</span>
      </div>
      <div v-if="type === 'shelf'">
        <vs-input v-validate="'required'" :label-placeholder="$t('Row ID')" name="row id" v-model="box.row" :class="box.row ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-xs" v-show="errors.has('row id')">{{ errors.first('row id') }}</span>
        <span class="text-danger text-xs" v-if="box.row && (box.row[0] !== 'R')">{{ $t('First letter must be R capital')}}</span>
        <span class="text-danger text-xs block" v-if="box.row && (box.row[1] !== 'R' && box.row[1] !== 'L')">{{ $t('Second letter must be R or L capital')}}</span>
      </div>
      <div v-if="type === 'shelf' || type === 'pallet'">
        <vs-input v-validate="'required'" :label-placeholder="$t('Aisle ID')" name="aisle id" v-model="box.aisle" :class="box.aisle ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-xs" v-show="errors.has('aisle id')">{{ errors.first('aisle id') }}</span>
        <span class="text-danger text-xs">{{ box.aisle && (box.aisle[0] !== 'A') ? $t('First letter must be A Capital') : box.aisle &&  (box.aisle.length === 1 || /\D/.test(box.aisle.slice(1))) ? $t('This field must have numeric data after first character') : ''}}</span>
      </div>
      <div v-if="type === 'shelf' || type === 'pallet'" class="sm:mt-0 mt-6">
        <v-select :class="box.fulfillment_center ? 'input-customer-info-filled' : ''" class="w-full input-customer-info product-dropdown" 
          autocomplete="nofill"  label="name" :name="`fulfillment center`" v-model="box.fulfillment_center" 
          :placeholder="$t('Fulfillment Center')" :options="fulfillmentCenters" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has(`fulfillment center`)">{{ errors.first(`fulfillment center`)}}</span>
      </div>
      <div class="col-span-1">
        <vs-input v-validate="'required|numeric'" :label-placeholder="$t('Capacity') + '*'" name="`capacity`" v-model="box.capacity" :class="box.capacity ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-xs" v-show="errors.has(`capacity`)">{{ errors.first(`capacity`) }}</span>
      </div>
      <div v-if="type === 'box'" class="sm:mt-0 mt-6">
        <v-select :class="box.storage_size ? 'input-customer-info-filled ' : ''" class="w-full input-customer-info product-dropdown" 
          autocomplete="nofill" label="name" :name="`size`" v-model="box.storage_size" 
          :placeholder="$t('Box Size') + '*'" :options="boxSizes" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has(`size`)">{{ errors.first(`size`)}}</span>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="addStorageUnit()" class="btn active-btn">{{ $t('Add') }}</button>
    </div>
  </shipblu-prompt>
</template>
<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'
export default {
  props: ['type',  'storageModal'],
  components: {
    ShipbluPrompt,
    vSelect
  },
  data () {
    return {
      fulfillmentCenters: [],
      shelfId: [],
      boxSizes: [],
      box: {},
      fulfillmentCentersDic: {},
      boxSizesDic: {},
      shelfIdDic: {}
    }
  },
  methods: {
    addStorageUnit () {
      this.$validator.resume()
      this.$validator.validateAll().then(result => {
        if (result && this.box.id[0] === this.type[0].toUpperCase()) {
          let data = {}
          if (this.type === 'box') {
            data = {
              ops_id: this.box.storage_size.short_code + this.box.id.slice(1), 
              storage_size: this.box.storage_size.id,
              storage_location: this.box.storage_location.id,
              capacity: Number(this.box.capacity)
            }
            this.sendData(data)
          } else if (this.type === 'tote') {
            data = {
              ops_id: this.box.id,
              storage_location: this.box.storage_location.id,
              capacity: Number(this.box.capacity)
            }
            this.sendData(data)
          } else if (this.type === 'pallet') {
            data = {
              ops_id: this.box.id,
              aisle: this.box.aisle,
              capacity: Number(this.box.capacity),
              fulfillment_center: this.box.fulfillment_center.id
            }
            this.addPalletLocation(data)
          } else if  (this.type === 'shelf') {
            data = {
              ops_id: this.box.id,
              aisle: this.box.aisle,
              row: this.box.row,
              capacity: Number(this.box.capacity),
              fulfillment_center: this.box.fulfillment_center.id
            }
            this.sendData(data)
          }
        }
      })
    },
    addPalletLocation (data) {
      const palletLocation = {
        capacity: 1,
        aisle: this.box.aisle,
        fulfillment_center: this.box.fulfillment_center.id,
        ops_id: this.box.id
      }
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/pallet-locations/', 'post', palletLocation, true,
        (response) => {
          this.sendData(data, response.data.id)
        }
      )
    },
    sendData (data, palletID) {
      if (this.type === 'pallet') {
        data = {
          ...data,
          storage_location: palletID
        }
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/${this.type === 'shelf' ? 'shelf-locations' : this.type === 'box' ? 'boxes' :  `${this.type}s`}/`, 'post', data, true,
        () => {
          this.closeModal()
        }
      )
    },
    loadFulfillmentCenters () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/fulfillment-centers/', 'get', null, true,
        (response) => {
          this.fulfillmentCenters = response.data.results
          this.fulfillmentCenters.forEach(item => {
            this.fulfillmentCentersDic[item.id] = item.name  
          })
        }
      )
    },
    loadShelfLocations () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/shelf-locations/', 'get', null, true,
        (response) => {
          this.shelfId = response.data.results
          this.shelfId.forEach(item => {
            this.shelfIdDic[item.id] = item.name  
          })
        }
      )
    },
    loadBoxSizes () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/storage-sizes/', 'get', null, true,
        (response) => {
          this.boxSizes = response.data.results
          this.boxSizes.forEach(item => {
            this.boxSizesDic[item.id] = item.name  
          })
        }
      )
    },
    closeModal () {
      this.$validator.pause()
      this.box = {}
      this.$emit('loadData')
      this.$emit('storageModal', false)
    }
  },
  created () {
    this.loadFulfillmentCenters()
    this.loadShelfLocations()
    this.loadBoxSizes()
  }
}
</script>